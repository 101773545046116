<div class="promotion-list-container">
	<h2>
		Current Promotion{ plural }
	</h2>
	<div class="promotion-list">
{#each promotions as promotion}
		<a href="/promotions" class="promotion"
				style="background-image: url({ promotion.image });">
			<div class="promotion-backdrop">
				<span class="title">
					{ promotion.title }
				</span>
				<p class="desc">
					{ promotion.desc }
				</p>
			</div>
		</a>
{/each}
	</div>

	<small>
		Promotions must be redeemed in front of a bartender.
		Requires Facebook login and a personal device.
	</small>

	<a href="/promotions" class="button outline accent">
		Redeem promotion{ plural }
	</a>
</div>

<script>
export default {
	data(){
		return {
			promotions: []
		}
	},

	computed: {
		plural(promotions){
			return promotions.length > 1 ? 's' : '';
		}
	}
};
</script>

<style>
	.promotion-list-container {
		padding: 2em 1em;
		font-size: 1.25em;
		text-align: center;
	}

	.promotion-list {
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		overflow-x: auto;
		overflow-y: hidden;
		text-align: left;
	}

	.promotion {
		flex-grow: 1;
		flex-shrink: 0;
		width: 300px;
		max-width: calc(100% - 1em);
		position: relative;
		padding: 0;
		display: block;
		border-radius: 5px;
		box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.25);
		margin: 1em;
		background-size: cover;
		background-position: center;
		color: white;
		text-decoration: none;
		opacity: 0.9;
		cursor: pointer;
		border: none;
		border-color: transparent;
		outline: none;
		outline-color: transparent;
	}

	.promotion:first-child {
		margin-left: 0.5em;
	}

	.promotion:last-child {
		margin-right: 0.5em;
	}

	.promotion:hover {
		transform: scale(1.01);
		box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.25);
		color: white;
		text-decoration: none;
	}

	.promotion-backdrop {
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 1em;
		background-color: rgba(0, 0, 0, 0.4);
	}

	.title {
		font-size: 1.6em;
		font-family: 'Oswald', sans-serif;
	}

	.desc {
		font-family: 'Lato', sans-serif;
		font-weight: 300;
	}

	small {
		display: block;
		margin: 2em 0;
	}
</style>
